<template>
  <div class="transition-login">
    <div v-if="!isError">
      正在跳转中...
    </div>
    <div v-else>
      链接有误，页面出错了！
    </div>
  </div>
</template>
<script>
import { appendParam } from '@/util/util';
import { parseUrl } from '@/util/uri';
import jsbridge from '@/jsbridge';
const checkurl = require('@tencent/checkurl');

// 此页面的作用：终端无法动态添加登陆参数 需要此页面来中转一下
export default {
  name: 'GamePayRedirect',
  data() {
    return {
      isError: false,
    };
  },
  mounted() {
    // 获取支付链接从fullPath获取 原因：ios终端会decode链接导致vue路由query参数提取失败
    let url = this.$route.fullPath.replace('/game-pay-redirect?openUrl=', '');
    if (!url) {
      jsbridge.ui
        .closeWebviews({
          mode: 1,
        })
        .then((res) => {
          this.$logger.info('gamePayRedirect closeWebviews', res);
        });
      return;
    }

    // 判断是否登录
    const { userInfo } = this.$store.state.user;
    url = decodeURIComponent(url);
    // 判断URL是否合法
    let checkStatus = false;
    if (url.indexOf('http') === 0) {
      checkStatus = checkurl(url, {
        schemes: ['http', 'https'],
        // rules: ['^(.+\\.)?qq\\.com$'],
        rules: ['^.*\\.?.qq\\.com$'],
        mode: 'regex',
      });
    }
    if (checkStatus) {
      const { hostname, pathname, protocol } = parseUrl(url);
      if (hostname === 'pay.qq.com' && userInfo?.uid !== 0 && (pathname === '/s/one' || pathname === '/h5/shop.shtml')) {
        url = protocol === 'http:' ? url.replace('http', 'https') : url;
        const urlParams = this.$store.getters['app/gamePayLoginParams'];
        url = appendParam(url, {
          ...urlParams,
        });
      }
      window.location.replace(url);
    } else {
      this.isError = true;
      console.error('URL check failed!');
    }
    // const { hostname, pathname, protocol } = parseUrl(url);
    // eslint-disable-next-line max-len
    // if (hostname === 'pay.qq.com' && userInfo?.uid !== 0 && (pathname === '/s/one' || pathname === '/h5/shop.shtml')) {
    //   url = protocol === 'http:' ? url.replace('http', 'https') : url;
    //   const urlParams = this.$store.getters['app/gamePayLoginParams'];
    //   url = appendParam(url, {
    //     ...urlParams,
    //   });
    // }
    // if (/^(.+\.)?qq\.com$/.test(hostname)) {
    //   window.location.replace(url);
    // } else {
    //   console.error('不支持非qq域名跳转');
    // }
  },

};
</script>
<style scoped lang='scss'>
.transition-login{
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 16px;
  color: 666;
  text-align: center;
}
</style>
